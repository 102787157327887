import React, { useEffect } from "react";
import ReactGA from "react-ga";
ReactGA.initialize(
  [
    {
      trackingId: "UA-158720281-1",
      gaOptions: { name: "noq" }
    }
  ],
  { alwaysSendToDefaultTracker: false }
);

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page, ["noq"]);
    window.fbq("track", "PageView");
  };

  const HOC = props => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};
