import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { withTracker } from "./withTracker";

function getRoutes() {
  if (process.env.REACT_APP_MODE === "pending") {
    return (
      <Switch>
        <Route
          path="/pending"
          component={withTracker(require("./Pending").default)}
        />
        <Redirect to="/pending" />
      </Switch>
    );
  } else if (process.env.REACT_APP_MODE === "end") {
    return (
      <Switch>
        <Route path="/end" component={withTracker(require("./End").default)} />
        <Redirect to="/end" />
      </Switch>
    );
  } else if (process.env.REACT_APP_MODE === "inprogress") {
    return (
      <Switch>
        <Route
          path="/result"
          component={withTracker(require("./Result").default)}
        />
        <Route
          path="/register"
          component={withTracker(require("./Register").default)}
        />
        <Redirect to="/register" />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route
          path="/result"
          component={withTracker(require("./Result").default)}
        />
        <Route
          path="/register"
          component={withTracker(require("./Register").default)}
        />
        <Route path="/end" component={withTracker(require("./End").default)} />
        <Route
          path="/pending"
          component={withTracker(require("./Pending").default)}
        />
        <Redirect to="/pending" />
      </Switch>
    );
  }
}

function App() {
  return <Router>{getRoutes()}</Router>;
}

export default App;
