import React from "react";

const Header = () => (
  <header className="App-header mb-4">
    <img
      className="mb-4"
      src={require("./assets/logo.png")}
      srcSet={`
    ${require("./assets/logo@2x.png")} 2x, 
    ${require("./assets/logo@3x.png")} 3x
  `}
    />
    <h1 className="text-3xl font-bold text-label mb-4">
      「C.A. Mask」
    </h1>
    <h2 className="text-2xl font-bold text-label mb-4">C.A. Mask on the face, keeps the virus away</h2>
  </header>
);

export default Header;
