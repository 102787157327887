import React from "react";

export function Footer() {
  return (
    <footer className="bg-footer py-4 px-8 lg:px-16 text-white text-center">
      Powered by{" "}
      <a
        className="hover:underline font-bold"
        href="https://www.noq.hk"
        target="_blank"
      >
        NoQ
      </a>
    </footer>
  );
}
