import React from "react";
import { Footer } from "./Footer";
import Header from "./Header";
import { Info } from "./Info";
import { ProductImage } from "./ProductImage";

function End(props) {
  return (
    <div className="bg-white w-full lg:w-185 lg:mx-auto lg:my-15 bg-white rounded shadow-lg">
      <div className="p-8 lg:p-15">
        <Header />
        <ProductImage />
        <div className="mb-8">
          <p className="text-3xl text-center">所有籌號已派發完畢，多謝參與。</p>
        </div>
        <Info />
      </div>
      <Footer />
    </div>
  );
}

export default End;
