import React from "react";

export function Info() {
  return (
    <>
      <div className="bg-remark p-4 rounded border border-label overflow-hidden">
        <h3 className="lg:text-xl font-bold mb-4">預購流程</h3>
        <h4 className="lg:text-lg font-bold mb-4">【活動詳情】</h4>
        <ol className="list-decimal list-inside">
          <li className="mb-4">口罩牌子：Classic Army Health Care Face Mask</li>
          <li className="mb-4">口罩規格：成人口罩50個裝，原材料熔噴布 BFE，PFE及VFE 達到99%以上</li>
          <li className="mb-4">預購價錢：選擇1: 2盒$300 - 送60 ML酒精搓手液一支; 或是 選擇2: 4盒$600 - 送500 ML 酒精搓手液一支</li>
          <li className="mb-4">發貨日期：7月7日起根據購買次序陸續發貨</li>
          <li className="mb-4">運輸費：是次活動，如果選擇順豐快遞均採用運費到付方式。</li>
          <li className="mb-4">贈品：所有贈品均需要憑贈品取貨號之電郵到旺角門市自取！</li>
        </ol>

        <h4 className="lg:text-lg font-bold mb-4">【簡易購買6步曲】</h4>
        <ol className="list-decimal list-inside">
          <li className="mb-4">登入領籌網址</li>
          <li className="mb-4">輸入電郵登記</li>
          <li className="mb-4">耐心等待結果</li>
          <li className="mb-4">成功獲得籌號</li>
          <li className="mb-4">等待購買連結及購買碼的電郵</li>
          <li className="mb-4">進入購買連結並以購買碼完成交易</li>
        </ol>

        <h4 className="lg:text-lg font-bold mb-4">【登記方式】</h4>
        <ol className="list-decimal list-inside">
          <li className="mb-4">於活動時間開始後，登入領籌網址，填寫電郵以作登記。</li>
          <li className="mb-4">每個電郵只可以領取一次籌號。</li>
          <li className="mb-4">所有填寫的資料必須正確，如有任何錯誤、遺漏或無法辨識等情況下將有機會自動作廢。</li>
          <li className="mb-4">登記一經確認，將不能更改任何登記資料。</li>
          <li className="mb-4">閣下所提供的資料將會用作是次登記之用。</li>
          <li className="mb-4">如有任何爭議，Classic Army Limited 將保留最終決定權。</li>
        </ol>

        <h4 className="lg:text-lg font-bold mb-4">【換領方式】</h4>
        <ol className="list-decimal list-inside">
          <li className="mb-4">每個成功登記，我們將會以電郵發放專屬你的購買連結及購買碼。該購買碼只能使用一次，購買兩盒/四盒口罩。</li>
          <li className="mb-4">電郵將於登記後隨即陸續發送，預計將於活動開始後10小時內發送所有電郵。</li>
          <li className="mb-4">為了確保你的電郵系統不會把我們系統發出的電郵誤當為垃圾郵件，你可加我們系統電郵地址 cs@boutir.com 為聯絡人，或檢查不會有電郵過濾把由 cs@boutir.com 寄出的電郵歸類為垃圾郵件。</li>
          <li className="mb-4">成功預訂後，請保留電子收據。在門市領取產品時，請向店員出示電子收據以悉別後才可提取。</li>
          <li className="mb-4">如有任何爭議，Classic Army Limited 將保留最終決定權。</li>
        </ol>

        <h3 className="lg:text-xl font-bold mb-4">條款及細則</h3>
        <ol className="list-decimal list-inside">
          <li className="mb-4">在產品預購結束後, 我們將無法接受取消或修改訂單, 也無法因個人原因而提出要求產品交換/退款/退貨；</li>
          <li className="mb-4">成功預訂後，請保留電子收據；</li>
          <li className="mb-4">每個籌號限定購買四盒口罩，發貨日期時間定於2020年7月7日起根據購買次序陸續發貨；</li>
        </ol>
        <p>
          若有任何問題，請直接聯絡 Classic Army Health Care 的 <a className="hover:underline" href="https://www.facebook.com/classicarmyhealthcare">FB 專頁</a>或電郵至{" "}
          <a className="hover:underline" href="mailto:healthcare@classicarmy.com">
            healthcare@classicarmy.com
          </a>
          。
        </p>
      </div>
    </>
  );
}
